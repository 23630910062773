import React from 'react';
import _, { isFunction } from 'lodash';
import Button, { VARIANTS } from 'common/components/Button';
import { Modal, ModalContent, ModalHeader, ModalFooter } from 'common/components/Modal';
import I18n from 'common/i18n';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import PendoIdWrapper, { DisplayStyle } from 'common/components/PendoIdWrapper';
import './Stepper.scss';

import {
  FinishOptions,
  StepperCircleProps,
  StepOptions,
  StepProps,
  WithStepperState,
  WithStepperOptions
} from './types';

const scope = 'shared.components.stepper';

// ie11 doesn't support set.values
function setToArray(aSet: Set<any>) {
  const anArr: any[] = [];
  aSet.forEach((val: any) => anArr.push(val));
  return anArr;
}

function StepperCircle({ filled, checkmarked, stepIdx, handleClick }: StepperCircleProps) {
  const classNames = ['stepper-circle'];
  if (filled) {
    classNames.push('stepper-circle-selected');
  }

  return (
    <div onClick={() => handleClick(stepIdx)} className={classNames.join(' ')}>
      {checkmarked ? <SocrataIcon name={IconName.Check2} /> : stepIdx + 1}
    </div>
  );
}

function renderStepCircles(
  steps: StepOptions[],
  seenSteps: Set<number>,
  handleClick: (stepIdx: number) => void
) {
  const numSteps = steps.length;
  const seenStepsArr = setToArray(seenSteps);
  const maxSeenStep = _.max(seenStepsArr) == undefined ? -1 : _.max(seenStepsArr);

  // current design guidance is that filled in means visited, checkmarked means valid
  // design also wants us to assume validity of last step and checkmark it when you land on it
  const visited = (i: number) => seenStepsArr.includes(i);
  const valid = (i: number) => visited(i) && i < maxSeenStep;
  const stepCircles = steps.map(({ title }, i) => {
    if (i + 1 === numSteps && visited(i)) {
      return (
        <div className="step-container" key={i}>
          <StepperCircle filled checkmarked handleClick={handleClick} stepIdx={i} />
          <span className="step-title">{title}</span>
        </div>
      );
    } else {
      return (
        <div className="step-container" key={i}>
          <StepperCircle filled={visited(i)} checkmarked={valid(i)} handleClick={handleClick} stepIdx={i} />
          <span className="step-title">{title}</span>
        </div>
      );
    }
  });

  return (
    <div className="stepper-circle-wrapper">
      <div className="stepper-circle-container">{stepCircles}</div>
    </div>
  );
}

export default class Stepper extends React.Component<WithStepperOptions, WithStepperState> {
  constructor(props: WithStepperOptions) {
    super(props);
    const stepState = props.steps.reduce((acc, a, idx) => {
      acc[`step-${idx}-state`] = {};
      return acc;
    }, {});

    // why do we copy all these things into the state?
    this.state = {
      modalSubtitle: props.modalSubtitle,
      modalTitle: props.modalTitle,
      step: props.startingStep || 0,
      seen: new Set(),
      selectedFinishOption: props.selectedFinish,
      workflowState: props.initialWorkflowState || {},
      ...stepState
    };

    this.handlePrev = this.handlePrev.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleCircleClick = this.handleCircleClick.bind(this);
    this.handleStepStateChange = this.handleStepStateChange.bind(this);
    this.handleWorkflowStateChange = this.handleWorkflowStateChange.bind(this);
    this.onChangeFinish = this.onChangeFinish.bind(this);
    this.setModalTitle = this.setModalTitle.bind(this);
    this.setModalSubtitle = this.setModalSubtitle.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      seen: this.state.seen.add(this.state.step)
    });
  }

  componentDidUpdate() {
    if (!this.state.seen.has(this.state.step)) {
      this.setState({
        // eslint-disable-line react/no-did-update-set-state
        seen: this.state.seen.add(this.state.step)
      });
    }
  }

  handlePrev() {
    if (this.state.step === 0) {
      return;
    }
    this.setState({
      step: this.state.step - 1
    });
  }

  handleNext() {
    const { step } = this.state;
    const { steps } = this.props;
    const nextStep = step + 1;

    const onLastStep = nextStep === steps.length;

    if (onLastStep) {
      const { selectedFinishOption } = this.state;
      if (selectedFinishOption.setBusy) {
        this.setState({ busy: true });
      }

      selectedFinishOption.action();
      return;
    }

    const { onSelectedNext } = steps[step];
    if (isFunction(onSelectedNext)) {
      onSelectedNext(this.propsForStep(nextStep));
    }

    this.setState({
      step: nextStep
    });
  }

  handleStepStateChange(newstate: any) {
    // @ts-ignore typescript hates this
    this.setState({
      [`step-${this.state.step}-state`]: {
        ...this.state[`step-${this.state.step}-state`],
        ...newstate
      }
    });
  }

  handleWorkflowStateChange(newState: any) {
    const { workflowState } = this.state;
    this.setState({
      workflowState: {
        ...workflowState,
        ...newState
      }
    });
  }

  setModalTitle(newTitle: string) {
    this.setState({ modalTitle: newTitle });
  }

  setModalSubtitle(newSubtitle: string) {
    this.setState({ modalSubtitle: newSubtitle });
  }

  handleCircleClick(step: number) {
    if (!this.props.showSteps) {
      // This should never happen
      return;
    }

    if (this.state.busy) {
      return;
    }

    // disable circle click handler if any step before
    // says the user cannot advance
    const disabled = Object.keys(this.state)
      .filter((k) => /step-/.test(k))
      .reduce((acc, a, i) => {
        // ignore what the current circle says about
        // advancement since we're already on it. Also
        // ignore what any circles that come after the
        // current one say about advancing past them
        if (step <= i) {
          return acc;
        }

        return acc || !!this.state[a].disableNext;
      }, false);

    if (disabled) {
      return;
    }

    this.setState({ step });
  }

  onChangeFinish(finishOption: FinishOptions) {
    this.setState({ selectedFinishOption: finishOption });
  }

  propsForStep(step: number): StepProps {
    const { busy, workflowState, selectedFinishOption } = this.state;
    const { finishOptions, pageOptions } = this.props;

    return {
      ...this.props,
      busy,
      finishOptions,
      handleWorkflowStateChange: this.handleWorkflowStateChange,
      handleStepStateChange: this.handleStepStateChange,
      setModalTitle: this.setModalTitle,
      setModalSubtitle: this.setModalSubtitle,
      onSelectFinish: this.onChangeFinish,
      pageOptions,
      stepState: _.omit(this.state, 'step', 'seen'),
      stepIdx: step,
      selectedFinish: selectedFinishOption,
      workflowState
    };
  }

  render() {
    const { busy, selectedFinishOption, seen, step } = this.state;
    const { steps, onDismiss, showSteps } = this.props;

    const currentStep = steps[step];
    const { disableNext } = this.state[`step-${step}-state`];
    const StepComponent = currentStep.component;
    const subtitle = this.state.modalSubtitle;

    return (
      <div id="common-stepper">
        <Modal dismissOnOutsideClick={false} onDismiss={onDismiss} containerStyle={{ maxWidth: 800 }}>
          <ModalHeader onDismiss={onDismiss} title={this.state.modalTitle}>
            {subtitle && <div className="modal-subtitle">{subtitle}</div>}
          </ModalHeader>
          <ModalContent className="stepper-modal-content">
            {showSteps && renderStepCircles(steps, seen, this.handleCircleClick)}
            <StepComponent {...this.propsForStep(step)} />
          </ModalContent>
          <ModalFooter>
            {/* TODO: the jsx and css here can be streamlined a lot */}
            <div className="button-container">
              <div className="left-footer">
                {step > 0 ? (
                  <PendoIdWrapper id={`step-${step}-back`} displayStyle={DisplayStyle.FLEX}>
                    <Button
                      onClick={this.handlePrev}
                      className="back-btn"
                      variant={VARIANTS.DEFAULT}
                      disabled={busy}
                    >
                      <SocrataIcon name={IconName.ArrowLeft} />
                      {I18n.t('back', { scope })}
                    </Button>
                  </PendoIdWrapper>
                ) : null}
              </div>
              <div className="right-footer">
                <PendoIdWrapper id={`step-${step}-cancel`} displayStyle={DisplayStyle.FLEX}>
                  <Button
                    onClick={onDismiss}
                    className="cancel-btn"
                    variant={VARIANTS.DEFAULT}
                    disabled={busy}
                  >
                    {I18n.t('cancel', { scope })}
                  </Button>
                </PendoIdWrapper>

                <PendoIdWrapper id={`step-${step}-next`} displayStyle={DisplayStyle.FLEX}>
                  <Button
                    onClick={disableNext ? _.noop : this.handleNext}
                    disabled={disableNext || busy}
                    busy={busy}
                    className="next-done-btn"
                    variant={VARIANTS.PRIMARY}
                  >
                    {step + 1 === steps.length ? (
                      selectedFinishOption.actionLabel
                    ) : (
                      <div>
                        {I18n.t('next', { scope })}
                        <SocrataIcon name={IconName.ArrowRight} />
                      </div>
                    )}
                  </Button>
                </PendoIdWrapper>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
