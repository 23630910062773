import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isInternalToPublicFederatedSoQLBasedView } from 'common/views/view_types';
import I18n from 'common/i18n';
import { fetchJsonWithDefaultHeaders } from 'common/http';
import Spinner from 'common/components/Spinner';
import { BODY } from './constants';

class Footer extends Component {
  state = {
    isDataLoading: false,
    otherlayerSourceDetails: {}
  };

  hasNoPrimaryDataSource = (dataSourceDetails) => _.isEmpty(_.get(dataSourceDetails, 'primaryDataSource'));

  UNSAFE_componentWillMount() {
    const { dataSourceDetails } = this.props;
    const { otherlayerSourceDetails } = this.state;

    const otherDataSources = _.get(dataSourceDetails, 'otherDataSources');

    if (this.hasNoPrimaryDataSource(dataSourceDetails) || _.isEmpty(otherDataSources)) {
      return;
    }

    const datasetDetailsPromise = _.map(otherDataSources, (dataSource) => {
      const url = `https://${dataSource.domain}/api/views/${dataSource.datasetUid}.json`;
      return fetchJsonWithDefaultHeaders(url, { credentials: 'same-origin' });
    });

    this.setState({ isDataLoading: true });

    Promise.all(datasetDetailsPromise).then((results) => {
      _.each(results, (resultItem) => {
        if (!isInternalToPublicFederatedSoQLBasedView(resultItem)) {
          const { id: datasetUid, name } = resultItem;
          otherlayerSourceDetails[datasetUid] = { datasetUid, name };
        }
      });
      this.setState({ otherlayerSourceDetails, isDataLoading: false });
    }).catch(() => {
      this.setState({ otherlayerSourceDetails: {}, isDataLoading: false });
    });
  }

  renderOtherlayersSourceLink = (otherDataSources) => {
    const { otherlayerSourceDetails } = this.state;

    if (_.isEmpty(otherlayerSourceDetails)) {
      return null;
    }

    return otherDataSources
      .filter(layerDataSource => otherlayerSourceDetails[layerDataSource.datasetUid])
      .reverse().map((layerDataSource, index) => {
        const { url, datasetUid } = layerDataSource;
        const name = _.get(otherlayerSourceDetails, [datasetUid, 'name'], null);
        return (
          <span key={index} className="layer-source-link">,
            {' '}
            <a href={url} target="_blank" rel="noreferrer">
              <em>{name}</em>
            </a>
          </span>
        );
      });
  }

  renderBaseLayerSourceLink = (baseLayerDataSource) => {
    return (
      <a href={baseLayerDataSource.url} target="_blank" rel="noreferrer">
        <em>{baseLayerDataSource.name}</em>
      </a>
    );
  }

  render() {
    const { dataSourceDetails } = this.props;
    const { isDataLoading } = this.state;

    if (this.hasNoPrimaryDataSource(dataSourceDetails)) {
      return (<span className="empty-space">&nbsp;</span>);
    }

    const otherLayersSourceLinks = isDataLoading ?
      <Spinner /> :
      this.renderOtherlayersSourceLink(dataSourceDetails.otherDataSources);

    return (
      <div className="data-sources-link">
        {I18n.t('visualization_canvas.info_pane.based_on')}
        {' '}
        {this.renderBaseLayerSourceLink(dataSourceDetails.primaryDataSource)}
        {otherLayersSourceLinks}
      </div>
    );
  }
}

Footer.propTypes = {
  dataSourceDetails: PropTypes.object
};

export default Footer;
