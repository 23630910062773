import ReactDOM from 'react-dom';
import React from 'react';
import defer from 'lodash/defer';

import './index.scss';

import { VARIANTS } from 'common/components/Button';

import ConfirmationModal from './ConfirmationModal';

/**
 * We heard you like options, so we put options in your options.
 *
 * These can be passed to the agree or cancel options.
 */
interface OptionOpts {
  /** Text to show inside the button */
  text?: string;

  /** Extra className to add to the button */
  className?: string;

  /** Button variant to use */
  variant?: VARIANTS;
}

interface Options {
  /** Options to pass to the agree button */
  agree?: OptionOpts;

  /** Options to pass to the cancel button */
  cancel?: OptionOpts;

  /** Text to show in the modal header */
  header?: string;

  /** Whether or not to hide the cancel button. It is shown by default. */
  hideCancelButton?: boolean;
}

/** This is returned from the promise once it resolves */
interface ConfirmationResponse {
  /** Whether or not the user confirmed what the modal was asking */
  confirmed: boolean;
}

/**
 * Find a DOM node by ID and, if it doesn't exist, create it.
 *
 * @param targetNodeId ID of node to create
 * @returns The found or created node
 */
const createTargetNodeIfNotExists = (targetNodeId: string): HTMLDivElement => {
  let targetNode = document.getElementById(targetNodeId) as HTMLDivElement | null;

  if (!targetNode) {
    targetNode = document.createElement('div');
    targetNode.setAttribute('id', targetNodeId);
    document.body.appendChild(targetNode);
  }

  return targetNode;
};

/**
 * Display a confirmation dialog. The returned promise resolves to an object
 * with the property 'confirmed' as true if the user agrees, false otherwise.
 * the object also has a closeModal property, which is a callback that closes the modal
 * @example
 * ```tsx
 * const confirmResolution = await confirmation(
 *  'Eject the warp core?',
 *  'confirmation-modal-container',
 *  {
 *    agree: { text: 'yep', className: 'btn-warning' },
 *    cancel: { text: 'nope' },
 *    header: 'WARNING'
 *  }
 * );
 *
 * console.log(confirmResolution.confirmed ? 'ejected!' : 'operation aborted');
 * ```
 *
 * @param body What to put in the body of the modal
 * @param targetNodeId ID of the node to render the modal into.
 *  If this does not exist, it will be created.
 * @param options Options for what to show in modal.
 * @returns A promise that will resolve with whether or not the user accepted the modal.
 */
const showConfirmationDialog = (
  body: (JSX.Element & React.ReactNode) | string,
  targetNodeId: string,
  options?: Options
): Promise<ConfirmationResponse> => {
  const targetNode = createTargetNodeIfNotExists(targetNodeId);

  return new Promise((resolve: (response: ConfirmationResponse) => void) => {
    const onCloseModal = (confirmed: boolean) =>
      defer(() => {
        ReactDOM.unmountComponentAtNode(targetNode); // Assumes targetNode is never null.....
        resolve({ confirmed });
      });

    ReactDOM.render(
      <ConfirmationModal
        onAgree={() => onCloseModal(true)}
        agreeButtonText={options?.agree?.text}
        agreeButtonVariant={options?.agree?.variant}
        onCancel={() => onCloseModal(false)}
        cancelButtonText={options?.cancel?.text}
        hideCancelButton={options?.hideCancelButton}
        headerText={options?.header}
      >
        {body}
      </ConfirmationModal>,
      targetNode
    );
  });
};

export default showConfirmationDialog;
