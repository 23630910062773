import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ESCAPE } from 'common/dom_helpers/keycodes_deprecated';

import Bell from 'common/notifications/components/Bell/Bell';
import { ACTIVITY_TAB_TYPE, DEFAULT_FILTER_TAB } from 'common/notifications/constants';
import NotificationList from 'common/notifications/components/NotificationList/NotificationList';
import TransientNotifications from 'common/notifications/components/UserNotifications/TransientNotifications';
import {
  deleteNotification,
  deleteAllNotifications,
  toggleNotificationReadState
} from 'common/notifications/api/NotificationAPI';

import { loadPreferenceSettings } from 'common/notifications/actions/PreferenceActions';
import {
  loadNotifications,
  moveTransientNotificationIntoPanel,
  removeTransientNotification,
  seeNewNotifications
} from 'common/notifications/actions/UserNotificationActions';
import { toggleNotificationPanel } from 'common/notifications/actions/PanelActions';

export class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enqueuedUserNotifications: [],
      filterUserNotificationsBy: DEFAULT_FILTER_TAB,
      openClearAllUserNotificationsPrompt: false
    };
  }

  UNSAFE_componentWillMount() {
    const {
      showMyAlertPreference,
      loadPreferenceSettings
    } = this.props;

    loadPreferenceSettings();

    this.setState({
      filterUserNotificationsBy: (showMyAlertPreference ? DEFAULT_FILTER_TAB : ACTIVITY_TAB_TYPE)
    });
  }

  componentWillUnmount() {
    this.removeKeyboardEvents();
  }

  onSeeNewUserNotifications = (type) => {
    this.props.seeNewNotifications(type);
  }

  onClearUserNotification = (notificationId) => {
    deleteNotification(notificationId);
  }

  addKeyboardEvents = () => {
    // add an event listener to hide when clicking somewhere
    window.addEventListener('mouseup', this.hidePanelOnOutsideClick);

    // add an event listened to hide when ESC is pressed
    window.addEventListener('keyup', this.hidePanelOnEscapeKeypress);
  }

  removeKeyboardEvents = () => {
    window.removeEventListener('mouseup', this.hidePanelOnOutsideClick);
    window.removeEventListener('keyup', this.hidePanelOnEscapeKeypress);
  }

  filterUserNotifications = (filterUserNotificationsBy) => {
    this.setState({ filterUserNotificationsBy });
  }

  clearAllUserNotifications = () => {
    deleteAllNotifications();
    this.setState({ openClearAllUserNotificationsPrompt: false });
  }

  loadMoreUserNotifications = (type) => {
    const { loadNotifications, userNotifications } = this.props;
    loadNotifications(type, userNotifications[type].offset);
  }

  toggleClearAllUserNotificationsPrompt = (toggle) => {
    this.setState({ openClearAllUserNotificationsPrompt: toggle });
  }

  toggleNotificationPanel = () => {
    const {
      showMyAlertPreference,
      showUserNotifications,
      showNotificationPanel,
      onToggleNotificationPanel,
      isTransientNotificationEnabled
    } = this.props;

    let currentTab;

    currentTab = showMyAlertPreference ? DEFAULT_FILTER_TAB : ACTIVITY_TAB_TYPE;
    if (showUserNotifications && isTransientNotificationEnabled) {
      this.onSeeNewUserNotifications(currentTab);
    }

    if (!showNotificationPanel) {
      this.addKeyboardEvents();

    } else {
      this.removeKeyboardEvents();

      this.setState({
        filterUserNotificationsBy: currentTab,
        isSecondaryPanelOpen: false,
        openClearAllUserNotificationsPrompt: false
      });
    }
    onToggleNotificationPanel(!showNotificationPanel);
  }

  hasEnqueuedUserNotifications = (type) => {
    return _.some(this.props.enqueuedUserNotifications, { type });
  }

  hidePanelOnOutsideClick = (event) => {
    if (event.target &&
      event.target.closest &&
      !event.target.closest('#socrata-notifications-container')) {
      this.toggleNotificationPanel();
    }
  }

  hidePanelOnEscapeKeypress = (event) => {
    if (event.keyCode === ESCAPE) {
      this.toggleNotificationPanel();
    }
  }

  renderSidebarOverlay = () => {
    return (<span
      aria-hidden="true"
      className="sidebar-overlay"
      onClick={this.toggleNotificationPanel}></span>);
  }

  renderNotificationPanel = () => {
    const {
      hasError,
      viewOlderLink,
      userNotifications,
      unreadUserNotificationCount,
      showNotificationPanel
    } = this.props;

    if (showNotificationPanel) {
      const {
        filterUserNotificationsBy,
        openClearAllUserNotificationsPrompt
      } = this.state;

      return (
        <div className="notifications-panel-wrapper">
          {this.renderSidebarOverlay()}

          <NotificationList
            clearAllUserNotifications={this.clearAllUserNotifications}
            filterUserNotifications={this.filterUserNotifications}
            filterUserNotificationsBy={filterUserNotificationsBy}
            hasEnqueuedUserNotifications={this.hasEnqueuedUserNotifications}
            hasError={hasError}
            onClearUserNotification={this.onClearUserNotification}
            onLoadMoreUserNotifications={this.loadMoreUserNotifications}
            onSeeNewUserNotifications={this.onSeeNewUserNotifications}
            onToggleReadUserNotification={toggleNotificationReadState}
            openClearAllUserNotificationsPrompt={openClearAllUserNotificationsPrompt}
            toggleClearAllUserNotificationsPrompt={this.toggleClearAllUserNotificationsPrompt}
            toggleNotificationPanel={this.toggleNotificationPanel}
            unreadUserNotificationCount={unreadUserNotificationCount}
            userNotifications={userNotifications}
            viewOlderLink={viewOlderLink} />
        </div>
      );
    }
  }

  renderTransientNotifications = () => {

    const {
      moveTransientNotificationIntoPanel,
      removeTransientNotification,
      showNotificationPanel,
      transientNotifications,
      isTransientNotificationEnabled
    } = this.props;

    if (!showNotificationPanel && isTransientNotificationEnabled && !_.isEmpty(transientNotifications)) {
      return (
        <TransientNotifications
          onClearUserNotification={this.onClearUserNotification}
          onToggleReadUserNotification={toggleNotificationReadState}
          moveTransientNotificationIntoPanel={moveTransientNotificationIntoPanel}
          removeTransientNotification={removeTransientNotification}
          transientNotifications={transientNotifications} />
      );
    }

    return null;
  }

  render() {
    const { unreadUserNotificationCount } = this.props;

    return (
      <div className="notifications-container">
        <div id="socrata-notifications-container">
          <Bell
            unreadUserNotificationCount={unreadUserNotificationCount}
            toggleNotificationPanel={this.toggleNotificationPanel} />
          {this.renderTransientNotifications()}
          {this.renderNotificationPanel()}
        </div>
      </div>
    );
  }
}

Notifications.propTypes = {
  hasError: PropTypes.bool,
  showMyAlertPreference: PropTypes.bool,
  showUserNotifications: PropTypes.bool.isRequired,
  isTransientNotificationEnabled: PropTypes.bool.isRequired
};


Notifications.defaultProps = {
  showMyAlertPreference: false
};

const mapDispatchToProps = {
  loadNotifications,
  loadPreferenceSettings,
  moveTransientNotificationIntoPanel,
  onToggleNotificationPanel: toggleNotificationPanel,
  removeTransientNotification,
  seeNewNotifications
};

function mapStateToProps(state) {
  const {
    configurations,
    panelConfigs,
    userId,
    userNotificationsConfigs,
    preferenceSettings
  } = state;
  return {
    enqueuedUserNotifications: userNotificationsConfigs.enqueuedNotifications,
    showMyAlertPreference: configurations.showMyAlertPreference,
    showNotificationPanel: panelConfigs.showNotificationPanel,
    showUserNotifications: configurations.showUserNotifications,
    transientNotifications: userNotificationsConfigs.transientNotifications,
    unreadUserNotificationCount: userNotificationsConfigs.unreadUserNotificationCount,
    userId,
    userNotifications: _.cloneDeep(userNotificationsConfigs.userNotifications),
    isTransientNotificationEnabled: _.get(preferenceSettings, 'isTransientNotificationEnabled', false)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);

