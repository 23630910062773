import _ from 'lodash';
import $ from 'jquery';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import I18n from 'common/i18n';
import Tabs from 'common/notifications/components/Tabs/Tabs';

import PanelHeader from 'common/notifications/components/NotificationList/PanelHeader';
import PanelFooter from 'common/notifications/components/NotificationList/PanelFooter';

const scope = 'shared.site_chrome.notifications';

export class NotificationList extends Component {
  UNSAFE_componentWillMount() {
    if (this.isNotSiteChromeAdminHeader) {
      // disable page scrolling when site chrome admin header is not present
      $('html').scrollTop(0);
      $('body').css('overflow', 'hidden');
    }
  }

  componentDidMount() {
    if (this.isNotSiteChromeAdminHeader) {
      // reposition the notification panel below the site chrome header
      $('#notifications-sidebar').css('top', $('#site-chrome-header').outerHeight());
    }
  }

  componentWillUnmount() {
    if (this.isNotSiteChromeAdminHeader) {
      // enable page scrolling once the notification panel is closed
      $('body').css('overflow', '');
    }
  }

  isNotSiteChromeAdminHeader = !$('#site-chrome-admin-header').is(':visible');

  renderPanelHeader = () => {
    const {
      toggleNotificationPanel,
      unreadUserNotificationCount,
      showMyAlertPreference
    } = this.props;
    const panelHeaderText = I18n.t(showMyAlertPreference ? 'user_notifications_and_alerts' : 'user_notifications', { scope });
    const unreadCount = unreadUserNotificationCount;

    return (
      <PanelHeader
        onClosePanel={toggleNotificationPanel}
        panelHeaderText={panelHeaderText}
        unreadCount={unreadCount} />
    );
  }

  renderUserNotifications = () => {

    const {
      clearAllUserNotifications,
      filterUserNotifications,
      filterUserNotificationsBy,
      hasEnqueuedUserNotifications,
      hasError,
      onClearUserNotification,
      onLoadMoreUserNotifications,
      onSeeNewUserNotifications,
      onToggleReadUserNotification,
      openClearAllUserNotificationsPrompt,
      toggleClearAllUserNotificationsPrompt,
      userNotifications
    } = this.props;
    const filterTabs = _.orderBy(_.keys(userNotifications), [], 'desc');

    return (
      <Tabs
        clearAllUserNotifications={clearAllUserNotifications}
        filterNotifications={filterUserNotifications}
        hasEnqueuedUserNotifications={hasEnqueuedUserNotifications}
        hasError={hasError}
        onClearUserNotification={onClearUserNotification}
        onLoadMoreUserNotifications={onLoadMoreUserNotifications}
        onSeeNewUserNotifications={onSeeNewUserNotifications}
        onToggleReadUserNotification={onToggleReadUserNotification}
        openClearAllUserNotificationsPrompt={openClearAllUserNotificationsPrompt}
        selectedTab={filterUserNotificationsBy}
        tabs={filterTabs}
        toggleClearAllUserNotificationsPrompt={toggleClearAllUserNotificationsPrompt}
        userNotifications={userNotifications} />
    );
  }

  renderPanelFooter = () => {
    const {
      showUserNotifications
    } = this.props;

    if (showUserNotifications) {
      const {
        clearAllUserNotifications,
        openClearAllUserNotificationsPrompt,
        toggleClearAllUserNotificationsPrompt,
        userNotifications
      } = this.props;

      const hasUserNotifications = _.get(userNotifications, 'activity.notifications', []).length > 0 ||
        _.get(userNotifications, 'alert.notifications', []).length > 0;

      return (<PanelFooter
        clearAllUserNotifications={clearAllUserNotifications}
        hasUserNotifications={hasUserNotifications}
        openClearAllUserNotificationsPrompt={openClearAllUserNotificationsPrompt}
        toggleClearAllUserNotificationsPrompt={toggleClearAllUserNotificationsPrompt} />);
    }

    return null;
  }

  render() {
    const { showUserNotifications } = this.props;
    const className = classNames('notifications-panel clearfix', { 'socrata-notifications-sidebar': showUserNotifications });

    return (
      <div className={className} id="notifications-sidebar">
        {this.renderPanelHeader()}
        {this.renderUserNotifications()}
        {this.renderPanelFooter()}
      </div>
    );
  }
}

NotificationList.propTypes = {
  clearAllUserNotifications: PropTypes.func.isRequired,
  filterUserNotificationsBy: PropTypes.string.isRequired,
  hasEnqueuedUserNotifications: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  onClearUserNotification: PropTypes.func.isRequired,
  onLoadMoreUserNotifications: PropTypes.func.isRequired,
  onSeeNewUserNotifications: PropTypes.func.isRequired,
  onToggleReadUserNotification: PropTypes.func.isRequired,
  openClearAllUserNotificationsPrompt: PropTypes.bool.isRequired,
  showUserNotifications: PropTypes.bool.isRequired,
  toggleClearAllUserNotificationsPrompt: PropTypes.func.isRequired,
  toggleNotificationPanel: PropTypes.func.isRequired,
  unreadUserNotificationCount: PropTypes.number.isRequired,
  userNotifications: PropTypes.object.isRequired,
  viewOlderLink: PropTypes.string
};

const mapStateToProps = state => ({
  userId: state.userId,
  showUserNotifications: state.configurations.showUserNotifications,
  showMyAlertPreference: state.configurations.showMyAlertPreference
});

export default connect(mapStateToProps)(NotificationList);

