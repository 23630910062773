import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';

import ceteraUtils from 'common/cetera/utils';
import { mergedCeteraQueryParameters, fetchInitialResults } from 'common/components/AssetBrowser/lib/helpers/cetera';
import Autocomplete from 'common/autocomplete/components/Autocomplete';
import SocrataIcon from 'common/components/SocrataIcon';
import I18n from 'common/i18n';

import * as constants from '../lib/constants';
import ActiveFilterCount from './filters/active_filter_count';
import BackButton from './back_button';
import Pager from 'common/components/Pager';
import ResultCount from 'common/components/ResultCount';
import ResultCardContainer from './result_card_container';
import ResultListTable from './result_list_table';
import SortDropdown from './sort_dropdown';
import DerivedFromFilter from './filters/derived_from_filter';
import IdsFilter from './filters/ids_filter';
import AssetInventoryActions from './asset_inventory_actions';
import * as filters from '../actions/filters';
import * as mobile from '../actions/mobile';
import * as pager from '../actions/pager';
import * as pageSizeActions from '../actions/page_size';
import ClearFilters from './filters/clear_filters';
import { UPDATE_AUDIENCE, TRANSFER_OWNERSHIP } from '../reducers/catalog';

const DEFAULT_RESULTS_PER_PAGE = 10;
const scope = 'shared.asset_browser';

export class CatalogResults extends Component {
  UNSAFE_componentWillMount() {
    const {
      allFilters,
      fetchInitialResults,
      ids,
      initialResultsFetched,
      pageSize,
      parentIds,
      showDerivedFromFilter,
      showIdsFilter,
      updatePageSize
    } = this.props;

    if (showDerivedFromFilter) {
      _.merge(allFilters, { parentIds });
    }

    if (showIdsFilter) {
      _.merge(allFilters, { ids });
    }

    if (!initialResultsFetched) {
      fetchInitialResults({ pageSize });
      updatePageSize(pageSize);
    }
  }

  renderError = () => {
    if (this.props.fetchingResultsError) {
      const errorDetails = _.get(this.props, 'fetchingResultsErrorType', 'fetching_results');
      console.error('catalogResults:renderError: ', errorDetails);

      return (
        <div className="alert error">
          {I18n.t('errors.fetching_results', { scope })}
        </div>
      );
    }
  }

  renderTooManyResultsMessage() {
    if (this.props.resultSetSize >= 10000) {
      return (
        <div className="alert info">
          {I18n.t('result_card_container.too_many_results', { scope })}
        </div>
      );
    }
    return null;
  }

  // This function is used by Autocomplete to ensure that all active filter parameters are applied
  // to calls to the Cetera to fetch autocomplete suggestions. It is in this module in order to
  // have access to the allFilters prop.
  fetchAutocompleteSuggestions = (searchTerm, callback) => { // numberOfResults, anonymous args unused
    const { reduxState } = this.props;

    if (_.isEmpty(searchTerm)) {
      callback([]);
    } else {
      // EN-19556: hack to get proper state into query param filters
      const getState = () => { return reduxState; };
      const translatedFilters = mergedCeteraQueryParameters(getState);

      ceteraUtils.autocompleteQuery(searchTerm, translatedFilters).
        then(callback);
    }
  }

  clearQuery = () => {
    if (!_.isEmpty(this.props.currentQuery)) {
      this.props.clearSearch();
    }
  }

  renderTopbar = () => {
    const {
      additionalTopbarComponents,
      changeQ,
      clearSearch,
      currentQuery,
      ids,
      isMobile,
      onClose,
      parentIds,
      renderStyle,
      selectMode,
      showBackButton,
      showDerivedFromFilter,
      showFilters,
      showIdsFilter,
      showManageAssets,
      showSearchField,
      toggleFilters,
      viewingOwnProfile
    } = this.props;

    const autocompleteOptions = {
      animate: true,
      anonymous: false,
      collapsible: false,
      currentQuery: currentQuery,
      getSearchResults: this.fetchAutocompleteSuggestions,
      millisecondsBeforeSearch: 60,
      mobile: isMobile,
      onChooseResult: changeQ,
      onClearSearch: this.clearQuery
    };

    const clearFiltersProps = {
      buttonStyle: true,
      clearSearch,
      showTitle: false
    };

    const { targetUserId, targetUserDisplayName } = _.get(window, 'socrata.assetBrowser.staticData', {});

    const allAssetsButtonTitle = viewingOwnProfile ?
      I18n.t('view_all', { scope }) :
      I18n.t('view_user_assets', { scope, userName: targetUserDisplayName });

    const assetParams = viewingOwnProfile ?
      `tab=${constants.TAB_MY_ASSETS}` :
      `ownerId=${targetUserId}&ownerName=${escape(targetUserDisplayName)}`;
    const assetUrl = `/admin/assets?${assetParams}`;

    const allAssetsButton = showManageAssets ? (
      <div className="manage-assets-link">
        <a href={assetUrl}>
          <button className="btn btn-primary all-assets-button">
            {allAssetsButtonTitle}
            <SocrataIcon name="arrow-right" />
          </button>
        </a>
      </div>
    ) : null;

    const mobileFilterToggle = isMobile && showFilters ? (
      <a href="#" className="mobile-filter-toggle" onClick={toggleFilters}>
        {I18n.t('mobile.filters', { scope })}
        <ActiveFilterCount />
        <SocrataIcon name="arrow-right" />
      </a>
    ) : null;

    const clearFiltersButton = (isMobile || !showFilters) ? null : <ClearFilters {...clearFiltersProps} />;

    const topbarClassnames = classNames(
      'topbar clearfix',
      {
        'cards-container-topbar': renderStyle === constants.RENDER_STYLE_CARD,
        mobile: isMobile
      }
    );

    const sortDropdown = (renderStyle === constants.RENDER_STYLE_CARD) ? <SortDropdown /> : null;

    const searchField = showSearchField ?
      <Autocomplete {...autocompleteOptions} className="autocomplete" /> : null;

    const backButton = (selectMode === true && showBackButton === true) ? <BackButton onClick={onClose} /> : null;

    return (
      <div className={topbarClassnames}>
        {mobileFilterToggle}
        {backButton}
        {additionalTopbarComponents}
        {searchField}
        {showDerivedFromFilter && <DerivedFromFilter parentIds={parentIds} />}
        {showIdsFilter && <IdsFilter ids={ids} />}
        {sortDropdown}
        {clearFiltersButton}
        {allAssetsButton}
      </div>
    );
  }

  renderResults() {
    const {
      activeTab, actionElement, closeOnSelect, fetchingResults, onAssetSelected,
      onClose, renderStyle, openInNewTab, tabs, updateAudience, transferOwnership
    } = this.props;

    const spinner = fetchingResults ? (
      <div className="catalog-results-spinner-container">
        <span className="spinner-default spinner-large"></span>
      </div>
    ) : null;

    const resultListing = (renderStyle === constants.RENDER_STYLE_CARD) ? ResultCardContainer : ResultListTable;

    const resultListingProps = {
      columns: _.get(tabs[activeTab], 'props.columns'),
      actionElement,
      closeOnSelect,
      onAssetSelected,
      onClose,
      openInNewTab,
      updateAudience: updateAudience,
      transferOwnership: transferOwnership
    };

    return (
      <div className="table-wrapper">
        {React.createElement(resultListing, resultListingProps)}
        {spinner}
      </div>
    );
  }

  // EN-18329: Hide the Asset Inventory button when on "My Assets" or "Shared to Me" tabs.
  showAssetInventoryActions = () => {
    const { activeTab, enableAssetInventoryActions } = this.props;
    return enableAssetInventoryActions &&
      activeTab !== constants.TAB_MY_ASSETS &&
      activeTab !== constants.TAB_SHARED_TO_ME;
  }

  renderFooter = () => {
    const {
      activeTab,
      fetchingResults,
      pageNumber,
      pageSize,
      resultSetSize,
      showPager
    } = this.props;

    if (fetchingResults) {
      return;
    }

    const pagerProps = {
      changePage: this.props.changePage,
      currentPage: pageNumber,
      resultCount: resultSetSize,
      resultsPerPage: pageSize || DEFAULT_RESULTS_PER_PAGE
    };

    const resultCountProps = {
      pageNumber,
      resultsPerPage: pageSize || DEFAULT_RESULTS_PER_PAGE,
      total: resultSetSize
    };

    const pager = showPager && (
      <div className="pagination-and-result-count">
        <Pager {...pagerProps} />
        <ResultCount {...resultCountProps} />
      </div>
    );

    const renderedAssetInventoryActions = this.showAssetInventoryActions() && (
      <AssetInventoryActions />
    );

    const approvalsNotificationsLink = activeTab === constants.TAB_MY_QUEUE && (
      <div className="approvals-notifications-link-wrapper">
        <a href="/admin/activity_feed?tab=approvals" target="_blank">
          {I18n.t('footer.approval_history', { scope })}
          <SocrataIcon name="external" />
        </a>
      </div>
    );

    // If there's nothing inside the footer, don't render it at all.
    // TODO: When this expression get any longer, convert to .any() or .all() style.
    if (!showPager && !this.showAssetInventoryActions() && _.isEmpty(this.props.additionalBottombarComponents)) {
      return;
    }

    return (
      <div className="catalog-footer">
        {pager}
        {this.props.additionalBottombarComponents}
        {renderedAssetInventoryActions}
        {approvalsNotificationsLink}
      </div>
    );
  }

  render() {
    const { showPager } = this.props;

    const catalogResultsClassnames = classNames('catalog-results', {
      'mobile': this.props.isMobile,
      'footerless': !showPager && !this.showAssetInventoryActions()
    });

    return (
      <div className={catalogResultsClassnames}>
        {this.renderTopbar()}
        {this.renderTooManyResultsMessage()}
        {this.renderError()}
        {this.renderResults()}
        {this.renderFooter()}
      </div>
    );
  }
}

CatalogResults.propTypes = {
  actionElement: PropTypes.oneOfType([PropTypes.object, PropTypes. func]),
  activeTab: PropTypes.string.isRequired,
  additionalTopbarComponents: PropTypes.array,
  additionalBottombarComponents: PropTypes.array,
  allFilters: PropTypes.object,
  changePage: PropTypes.func.isRequired,
  changeQ: PropTypes.func.isRequired,
  clearSearch: PropTypes.func,
  currentQuery: PropTypes.string,
  enableAssetInventoryActions: PropTypes.bool,
  fetchInitialResults: PropTypes.func.isRequired,
  fetchingResults: PropTypes.bool,
  fetchingResultsError: PropTypes.bool,
  fetchingResultsErrorType: PropTypes.string,
  ids: PropTypes.array,
  initialResultsFetched: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onAssetSelected: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.object,
  page: PropTypes.string,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  parentIds: PropTypes.array,
  resultSetSize: PropTypes.number.isRequired,
  showBackButton: PropTypes.bool,
  showDerivedFromFilter: PropTypes.bool,
  showIdsFilter: PropTypes.bool,
  toggleFilters: PropTypes.func.isRequired,
  updatePageSize: PropTypes.func.isRequired,
  viewingOwnProfile: PropTypes.bool,
  updateAudience: PropTypes.func,
  transferOwnership: PropTypes.func
};

CatalogResults.defaultProps = {
  additionalTopbarComponents: [],
  additionalBottombarComponents: [],
  allFilters: {},
  currentQuery: '',
  fetchingResults: false,
  fetchingResultsError: false,
  ids: null,
  initialResultsFetched: false,
  onClose: _.noop,
  pageNumber: 1,
  pageSize: DEFAULT_RESULTS_PER_PAGE,
  parentIds: [],
  showBackButton: true,
  showDerivedFromFilter: false,
  showIdsFilter: false,
  tabs: {},
  viewingOwnProfile: true
};

const mapStateToProps = (state) => ({
  activeTab: state.header.activeTab,
  allFilters: state.filters,
  currentQuery: state.filters.q,
  fetchingResults: state.catalog.fetchingResults,
  fetchingResultsError: state.catalog.fetchingResultsError,
  fetchingResultsErrorType: state.catalog.fetchingResultsErrorType,
  initialResultsFetched: state.catalog.initialResultsFetched,
  isMobile: state.windowDimensions.isMobile,
  order: state.catalog.order,
  pageNumber: state.catalog.pageNumber,
  reduxState: state,
  resultSetSize: state.catalog.resultSetSize
});

const mapDispatchToProps = (dispatch) => ({
  changePage: (pageNumber) => dispatch(pager.changePage(pageNumber)),
  changeQ: (query) => dispatch(filters.changeQ(query)),
  clearSearch: () => dispatch(filters.clearSearch()),
  fetchInitialResults: (parameters) => dispatch(fetchInitialResults(parameters)),
  updateAudience: (updatePayload) => dispatch({type: UPDATE_AUDIENCE, updatePayload}),
  transferOwnership: (updatePayload) => dispatch({type: TRANSFER_OWNERSHIP, updatePayload}),
  toggleDerivedFromFilter: (value) => dispatch(filters.toggleDerivedFromFilter(value)),
  toggleFilters: () => dispatch(mobile.toggleFilters()),
  updatePageSize: (pageSize) => dispatch(pageSizeActions.updatePageSize(pageSize))
});

export default connect(mapStateToProps, mapDispatchToProps)(CatalogResults);
