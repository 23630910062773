import _ from 'lodash';
import React, { FunctionComponent } from 'react';
import {
  ForgeButton,
  ForgeButtonArea,
  ForgeDivider,
  ForgeExpansionPanel,
  ForgeList,
  ForgeListItem,
  ForgeOpenIcon,
  ForgeRadio
} from '@tylertech/forge-react';
import { IListItemSelectEventData } from '@tylertech/forge/esm/list';
import I18n from 'common/i18n';

export interface FacetOption {
  text: string;
  value: string;
  /** Options grouped inside the current option. Note that this component only supports one layer of nesting. */
  nestedChildren: Omit<FacetOption, 'nestedChildren'>[];
}

export interface FacetSectionProps {
  options: FacetOption[];
  title: string;
  /** Number of options (parents + children) to display before showing "See more" button  */
  optionsDisplayCount: number;
  facetName: string;
  onFacetOptionSelect: (facetName: string, facetOptionValue: string) => void;
  onFacetClear: (facetName: string) => void;
  selectedFacetOption?: string;
}

/** Renders a title with a list of options beneath it. Clicking on the
 * title will show or hide the list of options. This commponent also can limit how many
 * options are displayed before the rest are hidden behind a "Show All" button. To show
 * all options always, set the optionsDisplayCount to a very high number.
 */
const FacetSection: FunctionComponent<FacetSectionProps> = ({
  options,
  title,
  optionsDisplayCount,
  facetName,
  onFacetOptionSelect,
  onFacetClear,
  selectedFacetOption
}) => {
  const scope = 'shared.components.facet_sidebar.facet_section';
  const isSelectedInOptions =
    !!selectedFacetOption &&
    !!options.find((option) => {
      if (option.value == selectedFacetOption) {
        return true;
      }
      if (option.nestedChildren?.length > 0) {
        return option.nestedChildren.find((child) => {
          return child.value == selectedFacetOption;
        });
      }
      return false;
    });

  const totalOptionsCount = options.reduce(
    (sum, { nestedChildren }) => sum + (nestedChildren?.length ?? 0),
    options.length
  );

  const [isAnySelected, setIsAnySelected] = React.useState<boolean>(isSelectedInOptions);
  const [showSeeMoreButton, setSeeMoreButton] = React.useState<boolean>(
    totalOptionsCount > optionsDisplayCount
  );
  const [expanded, setExpanded] = React.useState<boolean>(true);

  const filterOptionOnClick = (evt: CustomEvent<IListItemSelectEventData>) => {
    // the evt.detail.value is the filter value, i.e. datasets
    if (evt.detail.value === undefined) {
      // we don't expect this to happen, but guarding against edge cases
      return;
    } else {
      setIsAnySelected(true);
      onFacetOptionSelect(facetName, evt.detail.value);
    }
  };

  function onToggleExpansionPanel({ detail }: CustomEvent<boolean>): void {
    setExpanded(detail);
  }

  const handleClearClick = (e: Event) => {
    setIsAnySelected(false);
    onFacetClear(facetName);
  };

  const handleSeeMoreButton = (e: Event) => {
    setSeeMoreButton(false);
  };

  const generateForgeListItem = (option: Omit<FacetOption, 'nestedChildren'>) => {
    const isSelected = option.value == selectedFacetOption;
    const labelId = facetName + '-radio-label';
    return (
      <ForgeListItem
        on-forge-list-item-select={filterOptionOnClick}
        value={option.value}
        selected={isSelected}
        key={option.value}
        wrap={true}
        forge-drawer-context={false}
      >
        <ForgeRadio slot="leading" dense={true}>
          <input
            type="radio"
            id={facetName + '-radio'}
            name={facetName + '-radio'}
            data-testid={facetName + '-' + option.value + '-radio-button'}
            checked={isSelected}
            readOnly={true} // Without this, React complains
            aria-labelledby={labelId}
          />
        </ForgeRadio>
        <span id={labelId} slot="title">
          {option.text}
        </span>
      </ForgeListItem>
    );
  };

  const renderListOptions = () => {
    const listOptions: JSX.Element[] = [];
    const optionsToShow = showSeeMoreButton ? optionsDisplayCount : options.length;

    let renderedOptionsCount = 0;

    for (const option of options) {
      listOptions.push(generateForgeListItem(option));
      renderedOptionsCount++;
      if (renderedOptionsCount >= optionsToShow) {
        break;
      }

      if (option.nestedChildren?.length > 0) {
        for (const child of option.nestedChildren) {
          listOptions.push(generateForgeListItem(child));
          renderedOptionsCount++;
          if (renderedOptionsCount >= optionsToShow) {
            break;
          }
        }
        // we are not adding one to renderedOptionsCount in this case
        if (renderedOptionsCount >= optionsToShow) {
          break;
        }
      }
    }

    return listOptions;
  };

  return (
    <div className="facet-expansion-panel">
      <ForgeDivider />
      <ForgeExpansionPanel open={expanded} on-forge-expansion-panel-toggle={onToggleExpansionPanel}>
        <ForgeButtonArea slot="header">
          <button
            slot="button"
            type="button"
            data-testid={'toggle-' + title + '-expansion-panel'}
            id={'toggle-' + title + '-expansion-panel'}
            aria-controls={title + ' -expansion-panel-content'}
            aria-expanded="false"
          >
            {I18n.t('toggle_section', { scope, facet_name: title })}
          </button>
          <div className="expansion-panel-header">
            <div className="expansion-panel-header-title forge-typography--subtitle1-secondary">{title}</div>
            {isAnySelected && (
              <ForgeButton
                type="dense"
                onClick={handleClearClick}
                data-testid={'clear-' + title + '-filter'}
                data-forge-ignore
              >
                <button type="button">
                  <span>{I18n.t('clear', { scope })}</span>
                </button>
              </ForgeButton>
            )}
            <ForgeOpenIcon />
          </div>
        </ForgeButtonArea>
        <div className="expansion-panel-content" id={title + '-expansion-panel-content'} role="group">
          <div role="radiogroup" className="empty">
            <ForgeList
              dense={true}
              key={title + '-list'}
              ariaLabel={I18n.t('choose_filter_option', { scope })}
            >
              {renderListOptions()}
            </ForgeList>
          </div>
          {showSeeMoreButton && (
            <ForgeButton type="flat" onClick={handleSeeMoreButton} data-testid={'show-more-' + title}>
              <button type="button" className="show-more-button">
                <span>{I18n.t('see_more', { scope })}</span>
              </button>
            </ForgeButton>
          )}
        </div>
      </ForgeExpansionPanel>
    </div>
  );
};

export default FacetSection;
